import { ensureObservable } from '../lib/knockout-utils';
import { queryString, FilePath, StreamParser } from '../lib';
import { Dialog, keyboard } from '../base';
import { Asset } from '../asset/asset';
import { actions as assetActions } from '../asset/actions';
import assetApi from '../asset/api';
import { OwnerInfo, SharingInfo } from '../share/api';
import { Breadcrumb } from '../browser/breadcrumb.vm';
import { itemSorterMixins } from '../view/sorter';
import { AssetCollection, createAssetViewModel, commonAssetViewMixin } from '../browser/asset.vm';
import { assetColumns } from '../browser/asset-columns';
import { folderViewMixin } from '../browser/home.vm';
import { filePreview } from './file-preview.vm';
import { fileResourceId } from './file';
import { parsePath as linkParsePath, getBase as linkGetBase, folderFromLookup } from './link';
import Router from '../vendor/ko-component-router';
import toolbar from '../view/action-toolbar.vm';
import { ConfirmDialogViewModel } from '../base/dialog';

const location = linkParsePath(window.location.pathname);
const base = linkGetBase(window.location.pathname, location.uuid);
const baseUrl = `${base}${location.uuid}`;
const baseMetadataUrl = `${base}:metadata/:stream/${location.uuid}`;

window.addEventListener('popstate', event => {
    if (filePreview.openFromUrl({ sections: {} }).willOpen) {
        event.preventDefault();
    }
});

window.FOLDER_LINK_VIEW = true;

class FolderLink {
    constructor () {
        const openPreview = (ctx) => filePreview.openFromUrl({ sections: {} }).promise;

        function keyboardMiddleware (ctx) {
            return {
                beforeRender () {
                    keyboard.pushKeyEvents({});
                },
                beforeDispose () {
                    keyboard.popKeyEvents();
                }
            };
        }

        Router.use(keyboardMiddleware);

        this.routes = {
            '/': [openPreview, 'root'],
            '/folder/:lookup*': [openPreview, 'folder']
        };
    }
}

ko.components.register('folder-link-preview', {
    viewModel: FolderLink,
    template: `
    <ko-component-router params="
      routes: routes,
      base: '${baseUrl}'">
    </ko-component-router>
  `
});

// Main views

const service = (() => {
    const buildUrl = options => {
        const { folder } = options;
        const url = `${baseMetadataUrl}${folder ? ('/folder/p:' + folder) : ''}/`;
        const queryParams = queryString.parse(window.location.search);
        return queryString.buildUrl(url, queryParams);
    };

    return {
        load: options => $.ajax({
            async: true,
            url: buildUrl(options),
            dataType: 'text',
            cache: false,
            xhr: () => {
                const xhr = $.ajaxSettings.xhr();
                const parser = new StreamParser();

                xhr.onprogress = e => {
                    const response = e.currentTarget.response;

                    parser.loadMore(response);

                    let text = parser.next();
                    do {
                        text && options.chunkCallback(text);
                        text = parser.next();
                    } while (text);
                };
                return xhr;
            }
        })
    };
})();

class LinkToFolderViewModel {
    constructor (ctx) {
        this.currentFolder = ko.observable(
            ctx.params.lookup ? folderFromLookup(ctx.params.lookup) : ''
        );
        this.breadcrumbs = ko.observable([]);
        this.sharingInfo = null;

        this.dropUpload = {};

        this.init({
            ctx,
            name: 'home',
            toolbarActions: {
                toggleListThumbs: true,
                qrCode: true,
                showInNomad: true
            },
            service,
            assets: Object.assign(
                new AssetCollection(ko.observableArray([]), this),
                { sort: this.sortAssets }
            ),
            columns: assetColumns.home
        });

        this.setupAssets();

        // setup uploader
        this.uploadEnabled = false;

        this.responseItems = [];

        this.streamLoadingHandlers = {
            stats: data => {
                this.responseItems = new Array(data.folders + data.files);
            },
            root_owner_info: data => {
                this.ownerInfo = OwnerInfo.fromLink(data);
            },
            root_sharing_info: data => {
                this.sharingInfo = SharingInfo.create(data);
            },
            info: data => {
                this.breadcrumbs(this.breadcrumbsFactory(data, this.ownerInfo));
            },
            folders: data => {
                data.forEach(([id, item]) => {
                    if (item.flags.is_mounted) {
                        this.responseItems[id] = null;
                        return;
                    }
                    this.responseItems[id] = this.itemViewModelFactory(item, this.ownerInfo, true);
                    this.assets.add(this.responseItems[id]);
                });
                this.sort();
            },
            files: data => {
                data.forEach(([id, item]) => {
                    this.responseItems[id] = this.itemViewModelFactory(item, this.ownerInfo, false);
                    this.assets.add(this.responseItems[id]);
                });
                this.sort();
            },
            thumbnails: data => {
                data.forEach(([id, thumbnail]) => {
                    const oldAsset = this.responseItems[id];
                    const newData = { ...oldAsset.data, thumbnail };
                    const newAsset = Object.assign(
                        Object.create(Object.getPrototypeOf(oldAsset)),
                        oldAsset,
                        { data: newData, thumbnail }
                    );
                    this.responseItems[id] = newAsset;
                    // it's a bad idea to replace all assets, because thumbnail info
                    // is not returned for all assets
                    // meaning some assets will be lost. better replace only the assets with thumbnails
                    this.assets.replace(oldAsset, newAsset);
                });
            },
            thumbnails_3d: data =>
                data.forEach(([id, thumbnail]) => {
                    const oldAsset = this.responseItems[id];
                    const newData = { ...oldAsset.data, thumbnail_3d: thumbnail };
                    const newAsset = Object.assign(
                        Object.create(Object.getPrototypeOf(oldAsset)),
                        oldAsset,
                        { data: newData, thumbnail }
                    );
                    this.responseItems[id] = newAsset;
                    this.assets.replace(oldAsset, newAsset);
                }),
            error: data => {
                if (data.status === 404) {
                    Dialog.alert({ name: 'dialog-404-error' });
                } else {
                    Dialog.alert({ name: 'dialog-internal-server-error' });
                }
            }
        };
    }

    partialUpdateAssets = data =>
        this.streamLoadingHandlers[data.type] && this.streamLoadingHandlers[data.type](data.data);

    load () {
        ensureObservable(this, 'loading', true);
        this.service.load({
            folder: this.currentFolder(),
            chunkCallback: this.partialUpdateAssets
        })
            .then(data => { }, error => {
                if (error.response && error.response.status === 404) {
                    Dialog.alert({ name: 'dialog-404-error' });
                } else {
                    Dialog.alert({ name: 'dialog-internal-server-error' });
                }
            })
            .then(() => {
                this.loading(false);
            }).then(() => {
                assetApi.linkVisit(location.uuid);
            });
    }

    getAssetViewUrl (asset) {
        return asset.isFolder
            ? `${baseUrl}/folder/p:${asset.prefix}/`
            : fileResourceId.fromAsset(asset).asUrl();
    }

    itemViewModelFactory (asset, ownerInfo, isFolder) {
        asset = isFolder
            ? Asset.fromFolder(asset, { ownerInfo, assetActions })
            : Asset.fromFile(asset, { ownerInfo, assetActions });
        return createAssetViewModel(asset, this.assets, {
            routerCtx: this.routerCtx
        });
    }

    breadcrumbsFactory (folder, ownerInfo) {
        const pathPrefix = FilePath(ownerInfo.sharedParentFolder || folder.prefix).folder();
        const sharedPath = FilePath(folder.prefix.replace(/\/$/, ''))
            .removePrefix(pathPrefix);
        const [home, ...rest] = FilePath(sharedPath).breadcrumbs();
        return [
            new Breadcrumb(home[0], `${baseUrl}/`, false, 0),
            ...rest.map(([name, path], i) => new Breadcrumb(name, `${baseUrl}/folder/p:${path}/`, false, i + 1))
        ];
    }
};

Object.assign(
    LinkToFolderViewModel.prototype,
    commonAssetViewMixin,
    folderViewMixin,
    itemSorterMixins.client
);

ko.components.register('root', {
    viewModel: LinkToFolderViewModel,
    template: { element: 'view-home' }
});

ko.components.register('folder', {
    viewModel: LinkToFolderViewModel,
    template: { element: 'view-home' }
});

ko.components.register('action-toolbar', {
    viewModel: { instance: toolbar },
    template: { element: 'action-toolbar' }
});

ko.components.register('global-action-toolbar', {
    viewModel: { instance: toolbar },
    template: { element: 'global-action-toolbar' }
});

ko.components.register('dialog-delete-comment', {
    viewModel: ConfirmDialogViewModel,
    template: { element: 'dialog-delete-comment' }
});
