import { utils } from '../lib';
import { SortColumn } from '../view/sorter';
const {
    text: compareText,
    number: compareNubmer
} = utils.comparators;

const columns = {
    name: new SortColumn(
        gettext('Name'), 'name', 'name',
        {
            asc: (x, y) => compareText(x.name, y.name, true),
            desc: (x, y) => compareText(x.name, y.name, false)
        }
    ),
    lastModified: new SortColumn(
        gettext('Last Modified'), 'date', 'last-mod',
        {
            asc: (x, y) => compareNubmer(x.lastModified, y.lastModified, true),
            desc: (x, y) => compareNubmer(x.lastModified, y.lastModified, false)
        }
    ),
    size: new SortColumn(
        gettext('Size'), 'size', 'size',
        {
            asc: (x, y) => compareNubmer(x.size, y.size, true),
            desc: (x, y) => compareNubmer(x.size, y.size, false)
        }
    ),
    sharedWith: new SortColumn(
        gettext('Shared With'), 'owner', 'shared-with',
        {
            asc: (x, y) => compareText(x.ownerInfo.ownerName, y.ownerInfo.ownerName, true),
            desc: (x, y) => compareText(x.ownerInfo.ownerName, y.ownerInfo.ownerName, false)
        },
        false
    ),
    sharedBy: new SortColumn(
        gettext('Owner'), 'owner', 'sh-by',
        {
            asc: (x, y) => compareText(x.ownerInfo.ownerName, y.ownerInfo.ownerName, true),
            desc: (x, y) => compareText(x.ownerInfo.ownerName, y.ownerInfo.ownerName, false)
        }
    ),
    sharedDate: new SortColumn(
        gettext('Date Shared'), 'shareDate', 'sh-date',
        {
            asc: (x, y) => compareNubmer(x.ownerInfo.shareDate, y.ownerInfo.shareDate, true),
            desc: (x, y) => compareNubmer(x.ownerInfo.shareDate, y.ownerInfo.shareDate, false)
        }
    ),
    lastSharedDate: new SortColumn(
        gettext('Last Shared'), 'lastShareDate', 'last-mod',
        {
            asc: (x, y) => compareNubmer(x.sharingInfo.lastShareDate, y.sharingInfo.lastShareDate, true),
            desc: (x, y) => compareNubmer(x.sharingInfo.lastShareDate, y.sharingInfo.lastShareDate, false)
        }
    )
};

const assetColumns = {
    home: [columns.name, columns.lastModified, columns.size],
    shared: [columns.name, columns.sharedWith, columns.lastSharedDate],
    'shared-with-me': [columns.name, columns.sharedBy, columns.sharedDate],
    'shared-with-me-folder': [columns.name, columns.lastModified, columns.size],
    trash: [columns.name, columns.lastModified],
    'trash-folder': [columns.name, columns.lastModified]
};

export { columns, assetColumns };
