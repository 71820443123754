import validators from '../lib/validators';
import { buildAction } from '../base/action';
import { Dialog } from '../base';
import { toast } from '../toast';
import assetApi from '../asset/api';

const FOLDER_EXISTS_MESSAGE = gettext('Folder with the same name already exists.');
const FOLDER_SAME_NAME_DIFFERENT_CASE_EXISTS_MESSAGE = gettext('A folder with the same name, but different letter case, already exists.');

const createFolderValidators = function (folders) {
    return {
        required: {
            message: gettext('This field is required.'),
            params: true
        },
        maxLength: {
            message: gettext('The maximum length is 255 characters.'),
            params: 255
        },
        validation: [
            {
                validator: value => {
                    return !ko.utils.unwrapObservable(folders)
                        .filter(item => item.name === value.replace(/\s*$/, '')).length;
                },
                message: FOLDER_EXISTS_MESSAGE
            },
            {
                validator: value => {
                    return !ko.utils.unwrapObservable(folders)
                        .filter(item => item.name.toLowerCase() === value.replace(/\s*$/, '').toLowerCase()).length;
                },
                message: FOLDER_SAME_NAME_DIFFERENT_CASE_EXISTS_MESSAGE
            },
            {
                validator: text => validators.folderName(text),
                message: gettext('Unsupported characters.')
            },
            {
                validator: text => validators.invalidName(text),
                message: gettext('Invalid name.')
            }
        ]
    };
};

function CreateFolderViewModel (params) {
    this.owner = params.owner;
    this.folders = params.folders;
    this.currentFolder = params.currentFolder;
    this.dialog = params.dialog;

    this.folder = ko.observable(gettext()).extend(createFolderValidators(this.folders));

    this.form = ko.validatedObservable({
        folder: this.folder
    });

    this.isLoading = ko.observable(false);

    this.send = () => {
        if (!this.form.isValid()) {
            return;
        }
        this.folder(this.folder().replace(/\s*$/, ''));
        this.isLoading(true);

        assetApi.createFolder(this.owner, this.currentFolder.replace(/\/$/, '') + '/' + this.folder().trim())
            .then(data => {
                toast('FOLDER_CREATION_COMPLETE');
                return data;
            })
            .catch(error => {
                if (error.response.status === 409) {
                    return Dialog.open({
                        component: 'alert',
                        ctx: {
                            template: {
                                text: FOLDER_EXISTS_MESSAGE
                            }
                        }
                    }).result.then(() => null, () => null);
                } else {
                    toast('FOLDER_CREATION_FAIL');
                }
            })
            .then(data => {
                this.isLoading(false);
                if (data) {
                    this.dialog.close(data);
                }
            });
    };

    this.dismiss = () => {
        this.dialog.dismiss();
    };
}

function action (view) {
    const owner = ko.isObservable(view.owner) ? view.owner() : view.owner;

    return buildAction({
        action: () => {
            return Dialog.open({
                component: 'dialog-new-folder',
                ctx: {
                    owner,
                    folders: view.folders(),
                    currentFolder: view.currentFolder()
                }
            }).result.then(data => {
                const newFolder = view.itemViewModelFactory({
                    ...data,
                    // Make the check real-time or there's a risk of passing incomplete data.
                    owner_info: ko.isObservable(view.ownerInfo)
                        ? view.ownerInfo()
                        : view.ownerInfo
                }, true);
                view.assets.add(newFolder);
                view.sort();
                newFolder.open();
            });
        },
        properties: {
            isAllowed: view.storageType() === 's3',
            title: gettext('New folder'),
            icon: 'icon-add-folder'
        }
    }, 'createFolder', 'asset');
}

export {
    CreateFolderViewModel,
    action,
    createFolderValidators
};
