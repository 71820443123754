import { utils } from '../lib';

class Breadcrumb {
    constructor (name, href, active, level, closeSearch = null, reactDataBind = null) {
        this.name = decodeURI(name);
        this.href = href;
        this.active = !!active;
        this.level = level;
        this.closeSearch = closeSearch;
        this.reactDataBind = reactDataBind;
    }

    onClick (breadcrumb, event) {
        if (breadcrumb.active) {
            event.preventDefault();
        }
        return true;
    }
}

class BreadcrumbsViewModel {
    constructor (params) {
        this.breadcrumbs = params.breadcrumbs;
        this.maxVisibleCount = ko.unwrap(params.maxVisibleCount);

        this.visibleBreadcrumbs = ko.pureComputed(function () {
            const breadcrumbs = this.breadcrumbs().length > this.maxVisibleCount
                ? this.breadcrumbs().slice(this.breadcrumbs().length - this.maxVisibleCount)
                : this.breadcrumbs();
            const newBreadcrumbs = breadcrumbs.map(b => utils.copyObject(b));
            clearActive(newBreadcrumbs);
            setActive(newBreadcrumbs);
            return newBreadcrumbs;
        }, this);

        this.dropdownBreadcrumbs = ko.pureComputed(function () {
            const breadcrumbs = this.breadcrumbs().length > this.maxVisibleCount
                ? this.breadcrumbs().slice(0, this.breadcrumbs().length - this.maxVisibleCount)
                : [];
            const newBreadcrumbs = breadcrumbs.map(b => utils.copyObject(b));
            clearActive(newBreadcrumbs);
            return newBreadcrumbs;
        }, this);
    }
}

function setActive (items) {
    if (items.length > 0) {
        items[items.length - 1].active = true;
    }
}

function clearActive (items) {
    items.forEach(item => {
        item.active = false;
    });
}

ko.components.register('breadcrumbs', {
    viewModel: BreadcrumbsViewModel,
    template: { element: 'breadcrumbs-template' }

});

export {
    BreadcrumbsViewModel,
    Breadcrumb
};
