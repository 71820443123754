import { utils } from '../lib';
import { keyboard } from '../base';
import contextMenu from './context-menu';

const itemMixin = {
    getContextMenuActions () {
        return contextMenu.fromActions(
            this,
            Object.assign({}, this.actions, this.extraContextMenuActions || {}),
            this.contextMenuTemplate
        );
    },
    select () {
        this.selectable && this.selected(true);
    },
    deselect () {
        this.selected(false);
    }
};

function asCollectionItemViewModel (item, items) {
    if (item.isCollectionItem) {
        return item;
    }
    item.isCollectionItem = true;
    item.items = items;
    item.selected = ko.observable(false);
    item.selectable = true;
    item.contextMenuActions = ko.pureComputed(() => {
        const selection = items.selection();
        return selection.items.length > 1
            ? selection.contextMenuActions()
            : selection.items[0].getContextMenuActions();
    });
    return Object.assign(item, itemMixin);
};

const emptySelection = {
    items: [],
    hasAction (action) {
        return false;
    },
    doAction (action, context = null) {
        return () => {};
    },
    contextMenuActions () {
        return [];
    },
    actions: {},
    hasItem (item) {
        return false;
    }
};

class ItemSelection {
    static create (items) {
        const selectedItems = items.filter(item => item.selected());
        return (selectedItems.length === 0)
            ? emptySelection
            : (selectedItems.length === 1)
                ? ItemSelection.fromSingleItem(selectedItems[0])
                : new ItemSelection(selectedItems);
    }

    static fromSingleItem (item) {
        return {
            items: [item],
            hasAction: item.hasAction.bind(item),
            doAction: item.doAction.bind(item),
            contextMenuActions: item.contextMenuActions.bind(item),
            actions: item.actions,
            hasItem (i) {
                return item === i;
            }
        };
    }

    constructor (items) {
        this.items = items;
        const firstItem = this.items[0];
        const initialActionNames = firstItem.actionNames.filter(action => {
            return firstItem.actions[action].isMultiple(firstItem);
        });
        this.actionNames = Array.from(
            this.items
                .reduce((actionNames, item) => {
                    return utils.setIntersection(
                        actionNames,
                        item.actionNames.filter(action => {
                            return item.actions[action].isMultiple(item);
                        })
                    );
                },
                new Set(initialActionNames))
        );
        this.actions = this.actionNames
            .reduce((actions, action) => {
                actions[action] = firstItem.actions[action];
                return actions;
            },
            {});
    }

    hasAction (action) {
        return !!this.actions[action];
    }

    doAction (action, context = null) {
        return () => this.actions[action].action.call(context, this.items);
    }

    contextMenuActions () {
        return contextMenu.fromActions(
            this,
            this.actions,
            this.items[0].contextMenuTemplate
        );
    }

    hasItem (item) {
        return this.items.indexOf(item) !== -1;
    }
}

class ItemCollection {
    constructor (items, view) {
        this.items = items;
        this.view = view;
        this.selectionMode = ko.observable(false);
        this.isListView = ko.observable()
            .extend({ storage: { key: `preferences.${this.view.name}.isListView`, defaultVal: true } });
        this.isThumbView = ko.pureComputed(() => !this.isListView());
        this.hasItems = ko.pureComputed(() => this.items().length > 0);
        this.hasSelectedItems = ko.pureComputed(
            () => ko.utils.arrayFirst(this.items(), item => item.selected())
        );
        this.selection = ko.pureComputed(() => ItemSelection.create(this.items()));
        this.selectionContextMenuActions = ko.pureComputed(
            () => this.selection().contextMenuActions()
        );
        // Used for the hold-shift selection of items
        this.lastSelected = ko.observable(null);
        this.isTouchEvent = ko.observable(false);

        this.initSubscriptions();
    }

    initSubscriptions () {
        const keyActions = {
            cmmndA: {
                predicate: event => (event.metaKey || event.ctrlKey) && (event.which === 65 || event.which === 97),
                handler: () => {
                    this.selectAllItems();
                }
            }
        };
        this.keyEvents = keyboard.peekKeyEvents();
        this.keyEvents.extend(keyActions);
    }

    cleanup () {
        this.keyEvents.reduce();
    }

    toggleItemStyle () {
        this.isListView(!this.isListView());
    }

    find (itemOrPredicate) {
        return this.items().find(this.getPredicate(itemOrPredicate));
    }

    add (item) {
        const exists = !!this.items().find(i => item.equals(i));
        exists ? this.update([item]) : this.items.unshift(item);
    }

    remove (itemOrPredicate) {
        this.items.remove(this.getPredicate(itemOrPredicate));
    }

    update (updated) {
        const newItems = this.items()
            .map(oldItem => updated.find(item => item.equals(oldItem)) || oldItem);
        this.items(newItems);
        if (this.lastSelected()) {
            this.lastSelected(this.items().find(item => item.equals(this.lastSelected())));
        }
    }

    updateAll (newItems) {
        this.items(newItems);
    }

    replace (oldItem, newItem) {
        this.items.replace(oldItem, newItem);
    }

    concat (newItems) {
        this.items(this.items().concat(newItems));
    }

    sort (comparator) {
        this.items.sort(comparator);
    }

    length () {
        return this.items().length;
    }

    get (index) {
        return index === 'first'
            ? this.items()[0]
            : index === 'last'
                ? this.items()[this.length() - 1]
                : this.items()[index];
    }

    deselectItem (item) {
        item.deselect();
        if (!this.selection().items.length) {
            this.selectionMode(false);
        }
    };

    deselectAllItems () {
        this.items().forEach(item => item.deselect());
        this.selectionMode(false);
    };

    holdSelect (item, event) {
        if (!this.selectionMode()) {
            this.deselectAllItems();
            this.selectionMode(true);
        }
        item.select();
        return false;
    }

    clickSelect (item) {
        this.deselectAllItems();
        item.select();
        this.lastSelected(item);
    }

    selectFromTo (firstIndex, secondIndex) {
        for (let i = firstIndex; i <= secondIndex; i++) {
            this.items()[i].select();
        }
    }

    selectAllItems () {
        this.items().forEach(item => item.select());
    }

    rightClickSelect (item) {
        if (!this.selection().hasItem(item)) {
            this.clickSelect(item);
        }
    }

    makeSelection (item, event) {
        const contextMenu = event.type === 'contextmenu';

        if (event.ctrlKey || event.metaKey || this.selectionMode()) {
            item.selected() && !contextMenu ? this.deselectItem(item) : item.select();
            if (item.selected()) {
                this.lastSelected(item);
            } else {
                this.lastSelected(null);
            }
        } else if (event.shiftKey) {
            event.preventDefault();
            window.getSelection().removeAllRanges();
            if (!this.selection().items.length || !this.lastSelected()) {
                this.clickSelect(item);
            } else {
                const secondSelected = this.items.indexOf(item);
                const lastSelectedIndex = this.items.indexOf(this.lastSelected());
                const from = Math.min(lastSelectedIndex, secondSelected);
                const to = Math.max(lastSelectedIndex, secondSelected);
                this.deselectAllItems();
                this.selectFromTo(from, to);
            }
        } else {
            event.type === 'contextmenu' ? this.rightClickSelect(item) : this.clickSelect(item);
        }
        return true;
    };

    // Handles clicks, holds and selections for Mobiles and Desktop
    navigationAndSelection (item, event) {
        if (Settings.device.isMobile && !this.selectionMode()) {
            event.preventDefault();
            item.open();
        } else this.makeSelection(item, event);
    };

    selectDialogSelection (item, event) {
        if (Settings.device.isMobile) {
            this.clickSelect(item);
        } else this.navigationAndSelection(item, event);
    }

    getPredicate (itemOrPredicate) {
        return $.isFunction(itemOrPredicate)
            ? itemOrPredicate
            : i => itemOrPredicate.equals(i);
    }
}

export {
    asCollectionItemViewModel,
    ItemSelection,
    ItemCollection
};
