import { utils } from '../lib';

class SortColumn {
    constructor (name, code, cssClass, comparators, isSortable = true) {
        this.name = name;
        this.code = code;
        this.cssClass = cssClass;

        const capitalizedCode = utils.capitalize(code);

        this.sortedAsc = `sortedBy${capitalizedCode}Asc`;
        this.sortedDesc = `sortedBy${capitalizedCode}Desc`; ;

        this.sortAsc = `sortBy${capitalizedCode}Asc`;
        this.sortDesc = `sortBy${capitalizedCode}Desc`;

        this.comparators = comparators;
        this.isSortable = isSortable;
    }

    clone () {
        const newColumn = Object.create(Object.getPrototypeOf(this));
        Object.assign(newColumn, this);
        return newColumn;
    }

    update (fields) {
        const result = this.clone();
        Object.assign(result, fields);
        return result;
    }
}

class ListColumnsBarGenerator {
    constructor (params) {
        this.view = params.view;
        this.listItemCssClass = params.listItemCssClass;
        this.columns = params.columns;
    }
}

class ThumbSorter {
    constructor (params) {
        this.view = params.view;
        this.columns = params.columns.filter(column => column.isSortable);
        this.columnsStringList = this.columns.map(column => column.name);
        this.sortByAttribute = ko.observable(() => this.getCurrentColumn().name);
    }

    getColumnByName () {
        return this.columns.find(col => col.name === this.sortByAttribute());
    }

    getCurrentColumn () {
        const sortType = `sortedBy${utils.capitalize(this.view.getSortType())}`;
        return this.columns.find(col => col.sortedAsc === sortType || col.sortedDesc === sortType) ||
            this.columns[0];
    }
}

ko.components.register('thumb-sorter', {
    viewModel: ThumbSorter,
    template: { element: 'thumb-sorter' }
});

ko.components.register('list-columns-bar', {
    viewModel: ListColumnsBarGenerator,
    template: { element: 'list-columns-bar' }
});

const itemSorterMixins = (function () {
    const defaultSortType = 'nameAsc';
    const sorter = {
        server: {
            initSorting,
            getSortType,
            sort,
            getOrderingQs: function (sortType) {
                sortType = sortType || this.getSortType();
                const desc = sortType.endsWith('Desc') ? '-' : '';
                const ordering = sortType.replace(/(Asc|Desc)$/, '');
                return { ordering: desc + ordering };
            },
            createSortMethods () {
                for (const column of this.columns) {
                    for (const direction of ['Asc', 'Desc']) {
                        const sortType = `${column.code}${direction}`;
                        const method = `sortBy${utils.capitalize(sortType)}`;
                        this[method] = function () {
                            return this.sortBy(sortType, column.comparators[direction.toLowerCase()]);
                        };
                    }
                }
            },
            sortBy (sortType, comparator) {
                if (this.allItemsLoaded()) {
                    return sorter.client.sortBy.call(this, sortType, comparator);
                }
                clearSortedFlags.apply(this);
                this.load({ qs: this.getOrderingQs(sortType) }, true)
                    .then(() => {
                        this[`sortedBy${utils.capitalize(sortType)}`](true);
                    });
            }
        },
        client: {
            initSorting,
            getSortType,
            sort,
            createSortMethods () {
                for (const column of this.columns) {
                    for (const direction of ['Asc', 'Desc']) {
                        const sortType = `${column.code}${direction}`;
                        this[`sortBy${utils.capitalize(sortType)}`] = function () {
                            return this.sortBy(sortType, column.comparators[direction.toLowerCase()]);
                        };
                    }
                }
            },
            sortBy (sortType, comparator) {
                clearSortedFlags.apply(this);
                this.items.sort(comparator);
                this[`sortedBy${utils.capitalize(sortType)}`](true);
            }
        }
    };

    return sorter;

    function clearSortedFlags () {
        for (const sortType of this.sortTypes) {
            this[`sortedBy${utils.capitalize(sortType)}`](false);
        }
    }

    function createSortedFlags () {
        let flag;
        for (const sortType of this.sortTypes) {
            flag = `sortedBy${utils.capitalize(sortType)}`;
            this[flag] = ko.observable()
                .extend({ storage: { key: `preferences.${this.name}.sorting.${flag}`, defaultVal: false } });
        }
    }

    function getSortType () {
        return ko.utils.arrayFirst(
            this.sortTypes, sortType => this[`sortedBy${utils.capitalize(sortType)}`]()
        ) || defaultSortType;
    }

    function sort () {
        this[`sortBy${utils.capitalize(this.getSortType())}`]();
    }

    function initSorting () {
        this.sortTypes = this.columns.reduce((sortTypes, column) => {
            return sortTypes.concat([`${column.code}Asc`, `${column.code}Desc`]);
        }, []);
        this.createSortMethods();
        createSortedFlags.call(this);
    }
})();

export {
    SortColumn,
    itemSorterMixins
};
